import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import './consultation.css';

// import { css } from "styled-components/macro"; //eslint-disable-line

// import LogoImage from "../../images/logo.svg";
// import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

import { Container, ContentWithVerticalPadding } from "components/misc/Layouts";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const TextContent = tw.div`lg:py-8 text-center md:text-left flex items-center justify-center h-full flex-col mb-8`;

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight text-gray-300`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-400 max-w-screen-lg `;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full no-underline`
]);


export default ({
  heading = (
    <>
      Schedule a free consultation
    </>
  ),
  description = "Let's examine whether (and how) your port or waterway may benefit from SAFE HARBOUR Research & Technologies. Answer a few questions. It takes just 5 minutes.",
  primaryButtonText = "Schedule your consultation",
  primaryButtonUrl = "https://timerse.com",
  buttonRounded = true,
}) => {
  return (
    <div id="background">
    <Container>
          <TextContent>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
    </Container>
    </div>
  );
};
