import React from 'react';
import './Header.css';

import logo from "../images/safeHarbour/Logo/logominitextless.png";

function Header() {

  return(
  <header>
    <div></div>
    <div id="headerInfoWrapper">
    <img src={logo} style={{height: "100%"}}></img>
  <h1>SafeHarbour Research & Technologies</h1>
  <div id="buttonArea">
  <button>Live Demo</button>
  <button>Free Consultation</button>
  <button>About Us</button>
  <button>FAQ</button>
  </div>
    </div>
  </header>
  )
}

export default Header;