import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import Hero from "components/hero/FullWidthWithImage.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import SecondFeature from "components/features/ThreeColCenteredStatsPrimaryBackground.js"
import SliderCard from "components/cards/ThreeColSlider.js";
import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Blog from "components/blogs/ThreeColSimpleWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ThreeColSimple from "components/features/ThreeColSimple.js";
import Consultation from "components/text/Consultation";
import partners from "../images/safeHarbour/partners.png";
import ContactUsForm from "components/forms/SimpleContactUs.js";

import Header from "./Header";

import './Main.css';

import Hero from "components/hero/BackgroundAsImage.js";
//import yFeatures from "components/features/DashedBorderSixFeatures";
//import yMainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
//import yMainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import AISMap from "components/AISMap/AISMap";
import About from "components/AboutUs/About";



export default () => (
  <AnimationRevealPage>
    <Header/>
    <Hero /> {/*Yigit*/}

    <About/>

    {/*<SecondFeature/> Christiane*/}
    <AISMap/> {/*Tulio*/}
    <Blog />  {/*Christiane*/ }

    <Consultation /> {/*Yumi*/}
   

 
    <Testimonial textOnLeft={true}/> {/*Yumi*/}
    <FAQ /> {/*Yumi*/}
    <MainFeature /> {/*Christiane*/}
    <SubscribeNewsLetterForm /> {/*Yigit*/}
    {/*<ContactUsForm /> */} {/*Christiane*/}
    <Footer /> {/*Yumi*/}
  </AnimationRevealPage>
);
